define("discourse/plugins/discourse-restricted-replies/discourse/components/restrict-replies-setting", ["exports", "@ember/component", "discourse-common/lib/helpers", "discourse-common/utils/decorators"], function (_exports, _component, _helpers, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    groups() {
      return (0, _helpers.makeArray)(this.site.groups);
    }
  }, [["method", "groups", [_decorators.default]]]));
});